import React from "react";
import { graphql } from "gatsby";
import { PrevNextPage, Seo, PageBanner, Carousel } from "../components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import moment from "moment";

const EventWrapper = styled.div`
  ${tw`px-0 mx-4 md:mx-auto`}
`;

const EventDetailWrapper = styled.article`
  ${tw`mt-8 mb-6 mx-4 p-4 md:(mx-auto px-8 py-10) max-w-[640px] bg-secondary rounded-2xl`}
  box-shadow:12px 12px 24px 12px var(--gray-one);

  h3 {
    ${tw`w-full text-center leading-8`}
  }

  p {
    ${tw`whitespace-pre-line my-4`}
  }

  .contactButton {
    ${tw`block w-1/3 h-6 mt-2 mb-4 text-white bg-active rounded text-sm sm:(text-base mt-8) text-center transition duration-1000 ease-in-out mx-auto`}
    box-shadow:6px 6px 12px 6px var(--gray-one);
  }

  .contactButton:hover {
    box-shadow: none;
  }
`;

const EventTemplate = ({ data, pageContext: { prevPage, nextPage } }) => {
  const {
    photo,
    title,
    description: { description },
    addContactButton,
  } = data.contentfulEvent;
  let datetime = moment(data.contentfulEvent.datetime);
  const date = datetime.format("MMMM Do, YYYY");
  return (
    <>
      <Seo title={title} description={description} />
      <PageBanner
        img={
          <StaticImage
            src={"../images/william-white-TZCppMjaOHU-unsplash.jpg"}
            alt="Live concert with vivd red ambient light"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title={title}
        createdAt={date}
      />
      <div className="page-main">
        <EventWrapper>
          {photo ? <Carousel photo={photo} /> : null}

          <EventDetailWrapper>
            <h3>{title}</h3>
            <p>{description}</p>
            {addContactButton && Date.now() < datetime ? (
              <Link className="contactButton" to="/contact">
                Attend
              </Link>
            ) : (
              ""
            )}
          </EventDetailWrapper>
          <PrevNextPage {...{ target: "event", prevPage, nextPage }} />
        </EventWrapper>
      </div>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      datetime
      photo {
        gatsbyImageData(layout: CONSTRAINED)
        description
        id
      }
      title
      description {
        description
      }
      addContactButton
    }
  }
`;

export default EventTemplate;
